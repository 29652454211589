<template>
  <div v-if="huntArea" class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-third-widescreen is-one-third-fullhd">
    <RouterLink :to="`/area/${huntArea.id}/dashboard`" class="box">
      <h4 v-if="huntArea.name" class="title is-4 is-marginless">
        {{ capitalize(huntArea.name) }}
      </h4>

      <div class="buttons are-small">
        <RouterLink :to="`/area/${huntArea.id}/dashboard`" class="button">
          <span>{{ $t('areas.area') }}</span>
        </RouterLink>

        <RouterLink :to="`/calendar?huntAreaId=${huntArea.id}`" class="button">
          <span>{{ $t('calendar.title') }}</span>
        </RouterLink>
      </div>
    </RouterLink>
  </div>
</template>

<script>
export default {
  props: {
    huntArea: {
      type: Object,
      default: () => {}
    }
  },

  methods: {
    capitalize (string) {
      return this.$utils.capitalize(string)
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  position: relative;
  margin-bottom: 0;
  overflow: hidden;
  padding-bottom: 0.75rem;
  height: 100%;

  .title,
  .subtitle {
    position: relative;
    z-index: 1;
  }

  .subtitle {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    color: #646d6a;
  }

  .buttons {
    position: relative;
    z-index: 1;
    margin-top: 0.75rem;
    margin-bottom: 0;
  }

  a,
  a:visited {
    color: #333;
  }
}
</style>
