<template>
  <Fragment>
    <Wrapper class="page-huntareas">
      <Titlebar :isLoading="!huntAreas">
        <h5 slot="title" class="title is-5 is-marginless">{{ $t('nav.areas') }}</h5>

        <div slot="local">
          <div v-if="!isLoading && huntAreas.length > 0" class="field">
            <div class="control">
              <Button
                type="primary"
                :title="$t('huntArea.createHuntingGround')"
                @click="showCreateHuntAreaModal">
              </Button>
            </div>
          </div>
        </div>
      </Titlebar>

      <div class="default-spacing is-paddingless-top">
        <div v-if="isLoading" class="columns is-multiline is-mobile">
          <div v-for="i in 10" :key="i" class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-third-widescreen is-one-third-fullhd">
            <ContentPlaceholders class="box">
              <ContentPlaceholdersHeading :img="false" />
              <ContentPlaceholdersText :lines="1" />
            </ContentPlaceholders>
          </div>
        </div>

        <div v-else>
          <div v-if="huntAreas.length" class="columns is-multiline is-mobile">
            <HuntAreaCard
              v-for="huntArea in huntAreas"
              :key="huntArea.id"
              :huntArea="huntArea"
              class="area-item">
            </HuntAreaCard>
          </div>

          <EmptyState
            v-if="huntAreas.length === 0"
            icon="icon-crosshair"
            :tagline="$t('huntArea.noHuntAreasEmptyStateMessage')">
            <Button
              type="primary"
              :title="$t('huntArea.createHuntingGround')"
              @click="showCreateHuntAreaModal">
            </Button>
          </EmptyState>
        </div>
      </div>
    </Wrapper>

    <NewHuntAreaDialog v-if="huntAreaModalVisible" @close="closeCreateHuntAreaModal" />
  </Fragment>
</template>

<script>
import HuntAreaCard from './HuntAreaCard.vue'
import NewHuntAreaDialog from '@/components/dialogs/NewHuntAreaDialog.vue'

export default {
  components: {
    HuntAreaCard,
    NewHuntAreaDialog
  },

  data () {
    return {
      huntAreaModalVisible: false
    }
  },

  computed: {
    huntAreas () {
      const huntAreas = this.$store.getters['huntarea/areas']

      if (huntAreas !== null) {
        return huntAreas.slice(0).sort((a, b) => a.name.localeCompare(b.name))
      }

      return null
    },

    isLoading () {
      return this.huntAreas === null
    }
  },

  methods: {
    showCreateHuntAreaModal () {
      this.huntAreaModalVisible = true
    },

    closeCreateHuntAreaModal () {
      this.huntAreaModalVisible = false
    }
  }
}
</script>

<style lang="scss">
.column .box {
  .page-huntareas &:hover {
    box-shadow: 0 0.5em 1em -0.125em rgba(40, 40, 40, 0.1),
      0 0px 0 1px rgba(#eb914e, 0.75) !important;
  }

  &.no-huntareas {
    .box {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 10rem;
    }
  }
}
</style>
