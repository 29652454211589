import { render, staticRenderFns } from "./HuntAreaCard.vue?vue&type=template&id=4850d288&scoped=true&"
import script from "./HuntAreaCard.vue?vue&type=script&lang=js&"
export * from "./HuntAreaCard.vue?vue&type=script&lang=js&"
import style0 from "./HuntAreaCard.vue?vue&type=style&index=0&id=4850d288&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4850d288",
  null
  
)

export default component.exports